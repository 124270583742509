import {
  CTALinkProps,
  renderCTAMarkup,
} from "@/components/CTA/variants/CtaLink/shared";
import { ClientOnly } from "@/components/Cohesion/ClientOnly";
import ClientCTALink from "@/components/CTA/variants/CtaLink/client";
import { isTagularEnabledForElement } from "@/utils/tagular/helpers";

export default function CTALink(props: CTALinkProps) {
  /*
   * Tagular requires this to be Client Rendered, but we prefer server side
   * rendering we have split responsibility, and thus this class has
   * become the switching station.
   *
   * If we have enough info for this to be a Tagular evented CTA we call in the
   * client version, forcing the element to wait for window to come in to scope.
   *
   * Otherwise we fallback to our SSR version.
   */
  return isTagularEnabledForElement(props, false) ? (
    <ClientOnly>
      <ClientCTALink {...props} />
    </ClientOnly>
  ) : (
    renderCTAMarkup(props)
  );
}
