"use client";
import {
  useState,
  useMemo,
  FocusEventHandler,
  FormEvent,
  MouseEvent,
  ChangeEvent,
} from "react";

import { Search, Close } from "@/components/icons";
import { cn } from "@/lib/utils";
import debounce from "@/utils/debounce";
import { useProductSearchQuery } from "./ProductSearchContextProvider";
import useIsWebview from "@/hooks/useIsWebview";

interface SearchBoxProps {
  button: string;
  onFocus: FocusEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
  placeholder: string;
  variant?: string;
  onSubmit?: () => void;
  showButtons?: boolean;
  isRoundedButton?: boolean;
  isSearchBoxColumn?: boolean;
}

/**
 * Renders a search box component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onFocus - The onFocus event handler.
 * @param {Function} props.onBlur - The onBlur event handler.
 * @param {string} props.placeholder - The placeholder text for the search input.
 * @returns {JSX.Element} The rendered search box component.
 */
const SearchBox = ({
  button = "Search",
  onFocus,
  onBlur,
  placeholder,
  variant = "",
  onSubmit = () => {},
  showButtons,
  isRoundedButton,
  isSearchBoxColumn,
}: SearchBoxProps) => {
  const { query, setQuery } = useProductSearchQuery();
  const [searchFocused, setSearchFocused] = useState(false);
  const [input, setInput] = useState<string>(query);
  const { isWebview } = useIsWebview();
  const isNavHeaderVariant = variant === "nav-header";
  const isShopVariant = variant === "shop";

  // debounce search query
  const debouncedSearch = useMemo(
    () =>
      debounce((searchQuery, search) => {
        search(searchQuery);
      }, 300),
    [],
  );

  // update local state for form control and trigger search
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value;
    setInput(searchQuery);

    // count characters
    let keystrokesCount = searchQuery.length;

    // Only trigger the search after 3 chars
    if (keystrokesCount >= 3) {
      debouncedSearch(searchQuery, setQuery);
    }
  };

  // clear search query and reset form control
  const handleClear = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    e.preventDefault();
    setInput("");
    setQuery("");
  };

  // submit search query and redirect
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit();

    window.location.href = `/${isWebview ? "webview/search" : "search"}${
      input?.trim().length ? `?q=${input.trim()}` : ""
    }`;
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    onFocus(e);
    if (isNavHeaderVariant) {
      setSearchFocused(true);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur(e);
    if (isNavHeaderVariant) {
      setSearchFocused(false);
    }
  };

  // const handleSubmit
  return (
    <form
      data-testid="search-box"
      className={`flex overflow-hidden ${isSearchBoxColumn ? "flex-col" : ""} gap-2.5 md:flex-row md:gap-0`}
      onSubmit={(e) => handleSubmit(e)}
    >
      <div
        className={cn(
          "my-2 md:my-0 flex bg-white rounded min-h-12",
          !isShopVariant && "md:w-72",
          isNavHeaderVariant && "border border-gray-dark",
          isNavHeaderVariant &&
            (searchFocused || input?.trim().length) &&
            "md:w-[504px]",
        )}
      >
        <input
          type="search"
          className={cn(
            "border-0 grow focus-visible:ring-0 focus-visible:ring-offset-0 [&::-webkit-search-cancel-button]:hidden rounded w-[230px]",
            isNavHeaderVariant && "h-[44px] shadow-none",
          )}
          value={input}
          onChange={(e) => handleChange(e)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
        />
        {showButtons && (
          <>
            <button
              className={cn(
                (input?.trim().length ||
                  (isNavHeaderVariant && searchFocused)) &&
                  "hidden",
                "mx-4",
              )}
              type="submit"
              role="button"
              aria-label="Search for courses, certificates and degrees"
            >
              <Search />
            </button>
            <button
              className={cn(input?.trim().length ? "mx-4" : "hidden")}
              onClick={(e) => handleClear(e)}
            >
              <Close />
            </button>
          </>
        )}
      </div>
      <button
        className={cn(
          `${isRoundedButton ? "rounded-full" : ""} text-white text-base no-underline hover:no-underline bg-brand ml-0 md:ml-2 px-[1rem] py-[0.5625rem] border border-brand hover:bg-white hover:text-brand`,
          isNavHeaderVariant &&
            "bg-primary border-primary hover:text-white hover:border-secondary hover:bg-secondary",
          isNavHeaderVariant &&
            !searchFocused &&
            !input?.trim().length &&
            "md:hidden",
        )}
        type="submit"
      >
        {button}
      </button>
    </form>
  );
};

export default SearchBox;
