import { useState, useEffect } from "react";

const useIsWebview = () => {
  const [isWebview, setIsWebview] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsWebview(window.location.href.includes("webview"));
    }
  }, []);

  return { isWebview };
};

export default useIsWebview;
