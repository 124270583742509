import type { IconProps } from "./types";

const defaultProps = {
  svgProps: {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    role: "img",
    focusable: false,
    "aria-hidden": true,
  },
  pathProps: {
    fill: "currentColor",
  },
};

const CalendarChecked = ({
  svgProps = defaultProps.svgProps,
  pathProps = defaultProps.pathProps,
}: IconProps) => {
  const mergedSvgProps = { ...defaultProps.svgProps, ...svgProps };
  const mergedPathProps = { ...defaultProps.pathProps, ...pathProps };
  return (
    <svg {...mergedSvgProps}>
      <path
        d="M13.53 11.0358L12.47 9.97583L7.59 14.8558L5.47 12.7358L4.41 13.7958L7.59 16.9758L13.53 11.0358ZM18 2.97583H15V0.97583H13V2.97583H5V0.97583H3V2.97583H0V20.9758H18V2.97583ZM16 18.9758H2V7.97583H16V18.9758Z"
        {...mergedPathProps}
      />
    </svg>
  );
};

export default CalendarChecked;
