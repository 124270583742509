import React from "react";
import type { IconProps } from "./types";

const defaultProps = {
  svgProps: {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    role: "img",
    focusable: false,
    "aria-hidden": true,
  },
  pathProps: {
    fill: "currentColor",
  },
};

const TodoList = ({ svgProps, pathProps }: IconProps) => {
  const mergedSvgProps = { ...defaultProps.svgProps, ...svgProps };
  const mergedPathProps = { ...defaultProps.pathProps, ...pathProps };

  return (
    <svg {...mergedSvgProps}>
      <path
        d="M15.4502 8.0108L11.9102 4.4708L13.3202 3.0608L15.4402 5.1808L19.6802 0.940796L21.0902 2.3508L15.4502 8.0108ZM9.91016 4.0108H0.910156V6.0108H9.91016V4.0108ZM19.9102 10.4208L18.5002 9.0108L15.9102 11.6008L13.3202 9.0108L11.9102 10.4208L14.5002 13.0108L11.9102 15.6008L13.3202 17.0108L15.9102 14.4208L18.5002 17.0108L19.9102 15.6008L17.3202 13.0108L19.9102 10.4208ZM9.91016 12.0108H0.910156V14.0108H9.91016V12.0108Z"
        {...mergedPathProps}
      ></path>
    </svg>
  );
};

export default TodoList;
